import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import "./index.css";
import {
  AzureAdB2C,
  MSAL_CONFIG,
  RAFAuthModule,
  loginRequest,
} from "./RAFAzure/AuthModule";
import { IsNullOrWhiteSpace } from "./RAFComponents/helpers/utils";
import { firebaseConfig } from "./RAFFirebase";
import reportWebVitals from "./reportWebVitals";
//import { registerLicense } from "@syncfusion/ej2-base";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { RecoilRoot } from "recoil";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { RumComponentContextProvider } from "./RAFComponents/rum-react/RumComponentContext";
import { BrowserRouter } from "react-router-dom";
import { showErrorToast } from "./RAFComponents/Utility/RAFToastComponent";
import { Logout, hideProgress, showProgress } from "./RAFComponents/helpers/AppHelper";
import { error } from "console";

import("@syncfusion/ej2-base").then(ejBase => {
  ejBase.registerLicense(
    //'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0djWn1Wc31UR2dc'//26
    //'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkdhWn5Wc3VcT2Bb'//27
    'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkZjWXxcdHBWT2hZ'
  );
});

import("survey-core").then(surveyBase => {

  surveyBase.setLicenseKey(
    "N2E5MDA1OWYtOTVmZS00Mjc2LThmMDUtOWFkM2M0OWJmNmU5OzE9MjAyNS0wNS0wOQ=="
  );
});

const MAX_RETRIES = 3; // Maximum number of retries
let retryCount = 0; // Current retry count



export let msalInstance = new RAFAuthModule(MSAL_CONFIG);


// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
console.log("Accounts:", accounts);
if (!msalInstance.getActiveAccount() && accounts.length > 0) {
  //msalInstance.setActiveAccount(accounts[0]);
  // Filter accounts to match your authority
  const validAccount = accounts.find(
    (account) => account.tenantId === AzureAdB2C.Domain
  );

  if (validAccount) {
    msalInstance.setActiveAccount(validAccount);
  } else {
    console.warn("No accounts match the expected authority.");
  }
}

msalInstance.addEventCallback((event: EventMessage) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS)
    && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    //msalInstance.setActiveAccount(account);

    if (account.tenantId === AzureAdB2C.Domain) {
      msalInstance.setActiveAccount(account);
    } else {
      console.warn(
        "Account authority mismatch. Ignoring account:",
        account
      );
    }
  }
  else if (
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
    event.eventType === EventType.SSO_SILENT_FAILURE ||
    event.eventType === EventType.LOGIN_FAILURE
  ) {
    console.error('Authentication error:', event, accounts);
    //msalInstance.loginRedirect(loginRequest);
    if (event.error) {
      alert('Authentication error:' + JSON.stringify(event.error));
      switch (event.error['errorCode']) {
        case "interaction_required":
          // User needs to log in interactively
          console.warn("Interaction required. Redirecting to login...");
          msalInstance.loginRedirect(loginRequest);
          break;

        case "authority_mismatch":
          // Authority mismatch; prompt user to login with correct account
          console.error("Authority mismatch detected. Informing user...");
          alert("It seems you are trying to log in with an account from a different authority. Please log in with the correct account.");
          break;

        case "user_canceled":
          // User canceled login popup
          console.warn("User canceled login attempt.");
          alert("Login was canceled. Please try again.");
          break;

        case "timeout":
          // Timeout during login/token acquisition
          console.error("Timeout occurred during login or token acquisition.");
          alert("The operation timed out. Please try logging in again.");
          break;
        case "monitor_window_timeout":
          console.error("Silent token acquisition failed. Initiating interactive login.");
          //msalInstance.loginRedirect(loginRequest); 
          msalInstance.logoutRedirect(loginRequest);
          break;

        case "token_renewal_error":
          // Token renewal failed; require login
          console.error("Token renewal failed. Redirecting to login...");
          msalInstance.loginRedirect(loginRequest);
          break;

        default:
          // Generic error handling for unexpected failures
          console.error("Unexpected error occurred during authentication:", event.error['errorCode'], event.error['errorMessage'], event.error);
          if (event.error['errorCode'] === "unknown_error") {
            // Example: Retry login for an unknown recoverable error
            alert("An unexpected error occurred. We'll try to log you in again. If the issue persists, please contact support.");
            msalInstance.loginRedirect(loginRequest);
          } else {
            alert("An unexpected error occurred during login. Please refresh the page or contact support if the issue persists." + JSON.stringify(event.error));
          }
          break;
      }
    }
  }
});


const root = ReactDOMClient.createRoot(document.getElementById("root"));


//const app = initializeApp(firebaseConfig);
//export const firestoreDatabase = getFirestore(app);
// export const firestoreDatabase = initializeFirestore(app, {
//   ignoreUndefinedProperties: true,
// });

function MainApp() {
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {

    Promise.all([
      import("@syncfusion/ej2-base"),
      import("survey-core")
    ]).then(([ejBase, surveyBase]) => {
      ejBase.registerLicense(
        //'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0djWn1Wc31UR2dc'//26
        'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkZjWXxcdHBWT2hZ'
      );
      surveyBase.setLicenseKey(
        "N2E5MDA1OWYtOTVmZS00Mjc2LThmMDUtOWFkM2M0OWJmNmU5OzE9MjAyNS0wNS0wOQ=="
      );
      setInitialized(true);
    }).catch(error => {
      console.error("Error loading modules:", error);
    });

  }, []);

  useEffect(() => {
    if (isInitialized) {

      import("./RAFMaster/RMModules/FormLibrary/components/SurveyCreator/Initializations").then(ejBase => {
        ejBase.initialiseRAFSurveySettings();
      });

    }
  }, [isInitialized]);

  if (!isInitialized) {
    return <p>Loading...</p>;
  }
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <BrowserRouter>
          <App pca={msalInstance} />
        </BrowserRouter>
      </RecoilRoot>
    </ErrorBoundary>
  );

}

root.render(
  <MainApp />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
