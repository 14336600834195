import { DataManager } from "@syncfusion/ej2-data";
import { NavigateFunction } from "react-router-dom";
import { msalInstance } from "..";
import { getSessionStorage, setImpersonateUserDefinition, setSessionStorage } from "../RAFComponents/helpers/AppHelper";
import { Constants, ContentType, StorageKey } from "../RAFComponents/helpers/Constants";
import { MenuCategory, onToggleSideMenu } from "../RAFComponents/helpers/RAFMenuHelper";
import { IsNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../RAFComponents/helpers/utils";
import { ClientInfoRow } from "../RAFComponents/models/Common/ClientInfoRow";
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
import { RMStorageKey } from "../RAFMaster/helpers/RMConstants";
import { BusinessUnitRow } from "../SkytrustModules/Administration/BusinessUnit/BusinessUnitRow";
import { LogInAsRequestRow } from "../SkytrustModules/HR/Employee/Models/LogInAsRequestRow";
import * as repositoryActions from "./../RAFComponents/store/actions/repositoryActions";
import { postDataAndGetResponse } from "./../RAFComponents/store/actions/repositoryActions";
import { TenantRow } from "../RAFComponents/models/Common/TenantRow";

export function toggleActionMenu() {
    var topNavMenu = document.querySelector(".topNavMenu");
    if (topNavMenu != null) { topNavMenu.classList.toggle("increaseHeight"); };
}

export function toggleSideMenu() {

    var transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop != null) { transparentBackdrop.classList.toggle("show"); };

    const sidenav = document.getElementsByClassName("sidebar-nav");
    if (sidenav != null) {
        for (let i = 0; i < sidenav.length; i++) {
            sidenav[i].classList.toggle("mini-navbar");
        }
    };

    var toggler = document.querySelector(".toggler");
    if (toggler != null) { toggler.classList.toggle("active"); };


    var sidenavLogo = document.querySelector(".sidebar-nav .prodLogoImage");
    if (sidenavLogo != null) { sidenavLogo.classList.toggle("d-none"); };

    var sidenavShortLogo = document.querySelector(".sidebar-nav .logoShortImage.d-none");
    if (sidenavShortLogo != null) { sidenavShortLogo.classList.toggle("d-block"); };

    //var closeSideMenu = document.querySelector(".closeSideMenu");
    //if (closeSideMenu != null) { closeSideMenu.classList.toggle("mini-navbar") };

    var listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader != null) { listPageHeader.classList.toggle("mini-navbar"); };

    var listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent != null) { listPageContent.classList.toggle("mini-navbar"); };

    var detailsPageContent = document.querySelector(".detailsPanel");
    if (detailsPageContent != null) { detailsPageContent.classList.toggle("mini-navbar"); };

    var header = document.querySelector(".page-heading");
    if (header != null) { header.classList.toggle("mini-navbar"); }

    var footer = document.querySelector(".page-footer");
    if (footer != null) { footer.classList.toggle("mini-navbar"); }

    var topNavbar = document.querySelector(".topNavBar ");
    if (topNavbar != null) { topNavbar.classList.toggle("mini-navbar"); }

    var topNavMenu = document.querySelector(".topNavMenu ");
    if (topNavMenu != null) { topNavMenu.classList.toggle("mini-navbar"); }

    var topNavBUMenu = document.querySelector(".topNavBUMenu ");
    if (topNavBUMenu != null) { topNavBUMenu.classList.toggle("mini-navbar"); }

    var topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile != null) { topNavMenuProfile.classList.toggle("mini-navbar"); }

    var pageWrapper = document.querySelector(".page-wrapper ");
    if (pageWrapper != null) { pageWrapper.classList.toggle("mini-navbar"); }


    // var employeePageHeadingHeight = document.getElementById("employeePageHeading");
    // if (employeePageHeadingHeight != null) {
    //     employeePageHeadingHeight.classList.toggle("mini-navbar");
    // }
}

export function toggleRightSideMenu() {
    var rightnav = document.querySelector(".rightSidebar");
    if (rightnav != null) { rightnav.classList.toggle("show"); };

    var rightnavpoptriangle = document.querySelector(".pop-tiangle");
    if (rightnavpoptriangle != null) { rightnavpoptriangle.classList.toggle("show"); };

    var outsideClick = document.querySelector(".outsideClick");
    if (outsideClick != null) { outsideClick.classList.toggle("show"); };
}

export function toggleLeftSideMenu() {
    var transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop != null) {
        transparentBackdrop.classList.toggle("show");
    }

    var sidenav = document.querySelector(".sidebar-nav");
    if (sidenav != null) {
        sidenav.classList.remove("mini-navbar");
    }

    var listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader != null) {
        listPageHeader.classList.remove("mini-navbar");
    }

    var listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent != null) {
        listPageContent.classList.remove("mini-navbar");
    }

    // var employeePageHeadingHeight = document.getElementById("employeePageHeading");
    // if (employeePageHeadingHeight != null) {
    //     employeePageHeadingHeight.classList.remove("mini-navbar");
    // }
}

export function toggleTransparentBackdrop() {
    var transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop != null) { transparentBackdrop.classList.remove("show"); };

    var sidenav = document.querySelector(".sidebar-nav");
    if (sidenav != null) { sidenav.classList.remove("mini-navbar"); };

    var sidenavLogo = document.querySelector(".sidebar-nav .prodLogoImage");
    if (sidenavLogo != null) { sidenavLogo.classList.remove("d-none"); };

    var sidenavShortLogo = document.querySelector(".sidebar-nav .logoShortImage.d-none");
    if (sidenavShortLogo != null) { sidenavShortLogo.classList.remove("d-block"); };

    //var closeSideMenu = document.querySelector(".closeSideMenu");
    //if (closeSideMenu != null) { closeSideMenu.classList.toggle("mini-navbar") };

    var listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader != null) { listPageHeader.classList.remove("mini-navbar"); };

    var listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent != null) { listPageContent.classList.remove("mini-navbar"); };

    var detailsPageContent = document.querySelector(".detailsPanel");
    if (detailsPageContent != null) { detailsPageContent.classList.remove("mini-navbar"); };

    var header = document.querySelector(".page-heading");
    if (header != null) { header.classList.remove("mini-navbar"); }

    var footer = document.querySelector(".page-footer");
    if (footer != null) { footer.classList.remove("mini-navbar"); }

    var topNavbar = document.querySelector(".topNavBar ");
    if (topNavbar != null) { topNavbar.classList.remove("mini-navbar"); }

    var topNavMenu = document.querySelector(".topNavMenu ");
    if (topNavMenu != null) { topNavMenu.classList.remove("mini-navbar"); }

    var topNavBUMenu = document.querySelector(".topNavBUMenu ");
    if (topNavBUMenu != null) { topNavBUMenu.classList.remove("mini-navbar"); }

    var topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile != null) { topNavMenuProfile.classList.remove("mini-navbar"); }

    var pageWrapper = document.querySelector(".page-wrapper ");
    if (pageWrapper != null) { pageWrapper.classList.remove("mini-navbar"); }

    var actionMenuOuterDiv = document.querySelector(".actionMenu-outer-div");
    if (actionMenuOuterDiv != null) {
        actionMenuOuterDiv.classList.remove("active");
    }
    onToggleSideMenu('close');
};

export function handleScroll() {
    const wScroll = window.scrollY;
    toggleonScroll(wScroll);
};

export function togglebusinessUnit() {
    var rightnavBU = document.querySelector(".rightSidebarBU");
    if (rightnavBU != null) { rightnavBU.classList.toggle("show"); };
    var businessUnitDropdown = document.querySelector(".businessUnitDropdown");
    if (businessUnitDropdown != null) {
        businessUnitDropdown.classList.toggle("active");
    };
}


export function toggleonScroll(wScroll) {
    //var sidenav = document.querySelector(".sidebar-nav");
    //var topNavbar = document.querySelector(".topNavBar ");
    //var topNavMenubar = document.querySelector(".topNavMenu ");
    //var listHeader = document.querySelector(".listPageHeader ");
    //var pageHeader = document.querySelector(".page-heading");
    //var pageFooter = document.querySelector(".page-footer");
    var leftDetailsSection = document.querySelector(".leftDetailsSection");
    var rightDetailsSection = document.querySelector(".rightDetailsSection");
    var rightSidebarBU = document.querySelector(".rightSidebarBU");
    var leftSection = document.querySelector(".leftSection");
    var detailsLeftSection = document.querySelector(".detailsLeftSection");
    // var topNavMenu = document.querySelector(".topNavMenu");
    var topNavBUMenu = document.querySelector(".topNavBUMenu");
    var topNavBarHeight = document.getElementById("topNavBar");
    var topNavMenuHeight = document.getElementById("topNavMenu");
    var topNavBUMenuHeight = document.getElementById("topNavBUMenu");
    var employeePageHeadingHeight = document.getElementById("employeePageHeading");
    var incidentDetailsLeftSection = document.getElementById("leftSection");
    //var detailsLeftSectionTopSpace = topNavBarHeight + topNavMenuHeight + topNavBUMenuHeight + employeePageHeadingHeight;
    if (wScroll > 30) {
        /*if (sidenav != null) {
             if (sidenav.classList.contains(".mini-navbar")) {
                 sidenav.classList.add("mini-navbar");
                 sidenav.classList.add("scrolling-navbar");
                 sidenav.classList.add("top-nav-collapse");
             }
             else {
                 sidenav.classList.add("scrolling-navbar");
                 sidenav.classList.add("top-nav-collapse");
             }
         }
         if (topNavbar != null) {
             if (topNavbar.classList.contains(".mini-navbar")) {
                 topNavbar.classList.add("mini-navbar");
                 topNavbar.classList.add("scrolling-navbar");
                 topNavbar.classList.add("top-nav-collapse");
             }
             else {
                 topNavbar.classList.add("scrolling-navbar");
                 topNavbar.classList.add("top-nav-collapse");
             }
         }
         if (topNavMenubar != null) {
             if (topNavMenubar.classList.contains(".mini-navbar")) {
                 topNavMenubar.classList.add("mini-navbar");
                 topNavMenubar.classList.add("scrolling-navbar");
                 topNavMenubar.classList.add("top-nav-collapse");
             }
             else {
                 topNavMenubar.classList.add("scrolling-navbar");
                 topNavMenubar.classList.add("top-nav-collapse");
             }
         }
         if (listHeader != null) {
             if (listHeader.classList.contains(".mini-navbar")) {
                 listHeader.classList.add("mini-navbar");
                 listHeader.classList.add("scrolling-navbar top-nav-collapse");
                 listHeader.classList.add("top-nav-collapse");
             }
             else {
                 listHeader.classList.add("scrolling-navbar");
                 listHeader.classList.add("top-nav-collapse");
             }
         }
         if (pageHeader != null) {
             if (pageHeader.classList.contains(".mini-navbar")) {
                 pageHeader.classList.add("mini-navbar");
                 pageHeader.classList.add("scrolling-navbar top-nav-collapse");
                 pageHeader.classList.add("top-nav-collapse");
             }
             else {
                 pageHeader.classList.add("scrolling-navbar");
                 pageHeader.classList.add("top-nav-collapse");
             }
         }
         if (pageFooter != null) {
             if (pageFooter.classList.contains(".mini-navbar")) {
                 pageFooter.classList.add("mini-navbar");
                 pageFooter.classList.add("scrolling-navbar top-nav-collapse");
                 pageFooter.classList.add("top-nav-collapse");
             }
             else {
                 pageFooter.classList.add("scrolling-navbar");
                 pageFooter.classList.add("top-nav-collapse");
             }
         }
         */
        if (rightSidebarBU != null) {
            if (rightSidebarBU.classList.contains(".mini-navbar")) {
                rightSidebarBU.classList.add("mini-navbar");
                rightSidebarBU.classList.add("scrolling-navbar top-nav-collapse");
                rightSidebarBU.classList.add("top-nav-collapse");
            }
            else {
                rightSidebarBU.classList.add("scrolling-navbar");
                rightSidebarBU.classList.add("top-nav-collapse");
            }
        }
    }
    else if (wScroll < 30) {
        /*if (sidenav != null) {
            if (sidenav.classList.contains(".mini-navbar")) {
                sidenav.classList.add("scrolling-navbar");
                sidenav.classList.add("top-nav-collapse");
            }
            else {
                sidenav.classList.remove("scrolling-navbar");
                sidenav.classList.remove("top-nav-collapse");
            }
        }
        if (topNavbar != null) {
            if (topNavbar.classList.contains(".mini-navbar")) {
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
            else {
                topNavbar.classList.remove("scrolling-navbar");
                topNavbar.classList.remove("top-nav-collapse");
            }
        }
        if (topNavMenubar != null) {
            if (topNavMenubar.classList.contains(".mini-navbar")) {
                topNavMenubar.classList.add("scrolling-navbar");
                topNavMenubar.classList.add("top-nav-collapse");
            }
            else {
                topNavMenubar.classList.remove("scrolling-navbar");
                topNavMenubar.classList.remove("top-nav-collapse");
            }
        }
        if (listHeader != null) {
            if (listHeader.classList.contains(".mini-navbar")) {
                listHeader.classList.add("scrolling-navbar");
                listHeader.classList.add("top-nav-collapse");
            }
            else {
                listHeader.classList.remove("scrolling-navbar");
                listHeader.classList.remove("top-nav-collapse");
            }
        }
        if (pageHeader != null) {
            if (pageHeader.classList.contains(".mini-navbar")) {
                pageHeader.classList.add("scrolling-navbar");
                pageHeader.classList.add("top-nav-collapse");
            }
            else {
                pageHeader.classList.remove("scrolling-navbar");
                pageHeader.classList.remove("top-nav-collapse");
            }
        }
        if (pageFooter != null) {
            if (pageFooter.classList.contains(".mini-navbar")) {
                pageFooter.classList.add("scrolling-navbar");
                pageFooter.classList.add("top-nav-collapse");
            }
            else {
                pageFooter.classList.remove("scrolling-navbar");
                pageFooter.classList.remove("top-nav-collapse");
            }
        }
        */
        if (rightSidebarBU != null) {
            if (rightSidebarBU.classList.contains(".mini-navbar")) {
                rightSidebarBU.classList.add("scrolling-navbar");
                rightSidebarBU.classList.add("top-nav-collapse");
            }
            else {
                rightSidebarBU.classList.remove("scrolling-navbar");
                rightSidebarBU.classList.remove("top-nav-collapse");
            }
        }
    }

    if (topNavBarHeight != null && topNavMenuHeight != null && leftSection != null) {
        let detailsLeftSectionTopSpace = topNavBarHeight.offsetHeight + topNavMenuHeight.offsetHeight;
        if (wScroll > detailsLeftSectionTopSpace) {
            if (leftSection != null) {
                leftSection.classList.add("scrolled220");
                if (incidentDetailsLeftSection !== null)
                    incidentDetailsLeftSection.style.top = topNavMenuHeight.offsetHeight + "px";
            }
        }
        else if (wScroll < detailsLeftSectionTopSpace) {
            if (leftSection != null) {
                leftSection.classList.remove("scrolled220");
                if (incidentDetailsLeftSection !== null)
                    incidentDetailsLeftSection.style.top = "110px";
            }
        }
    }

    if (topNavBarHeight != null && topNavMenuHeight != null && employeePageHeadingHeight != null) {
        let detailsLeftSectionTopSpace = topNavBarHeight.offsetHeight + topNavMenuHeight.offsetHeight + employeePageHeadingHeight.offsetHeight;
        if (wScroll > detailsLeftSectionTopSpace) {
            if (leftDetailsSection != null) {
                if (leftDetailsSection.classList.contains(".mini-navbar")) {
                    leftDetailsSection.classList.add("mini-navbar");
                    leftDetailsSection.classList.add("scrolling-navbar top-nav-collapse");
                    leftDetailsSection.classList.add("top-nav-collapse");
                }
                else {
                    leftDetailsSection.classList.add("scrolling-navbar");
                    leftDetailsSection.classList.add("top-nav-collapse");
                }
            }
            if (rightDetailsSection != null) {
                if (rightDetailsSection.classList.contains(".mini-navbar")) {
                    rightDetailsSection.classList.add("mini-navbar");
                    rightDetailsSection.classList.add("scrolling-navbar top-nav-collapse");
                    rightDetailsSection.classList.add("top-nav-collapse");
                }
                else {
                    rightDetailsSection.classList.add("scrolling-navbar");
                    rightDetailsSection.classList.add("top-nav-collapse");
                }
            }
        }
        else if (wScroll < detailsLeftSectionTopSpace) {
            if (leftDetailsSection != null) {
                if (leftDetailsSection.classList.contains(".mini-navbar")) {
                    leftDetailsSection.classList.add("scrolling-navbar");
                    leftDetailsSection.classList.add("top-nav-collapse");
                }
                else {
                    leftDetailsSection.classList.remove("scrolling-navbar");
                    leftDetailsSection.classList.remove("top-nav-collapse");
                }
            }
            if (rightDetailsSection != null) {
                if (rightDetailsSection.classList.contains(".mini-navbar")) {
                    rightDetailsSection.classList.add("scrolling-navbar");
                    rightDetailsSection.classList.add("top-nav-collapse");
                }
                else {
                    rightDetailsSection.classList.remove("scrolling-navbar");
                    rightDetailsSection.classList.remove("top-nav-collapse");
                }
            }
        }
    }

    // if (wScroll > 142) {
    //     if (employeePageHeadingHeight != null) {
    //         if (employeePageHeadingHeight.classList.contains(".mini-navbar")) {
    //             employeePageHeadingHeight.classList.add("mini-navbar");
    //             employeePageHeadingHeight.classList.add("scrolling-navbar");
    //             employeePageHeadingHeight.classList.add("top-nav-collapse");
    //         }
    //         else {
    //             employeePageHeadingHeight.classList.add("scrolling-navbar");
    //             employeePageHeadingHeight.classList.add("top-nav-collapse");
    //         }
    //     }
    // }
    // else if (wScroll < 142) {
    //     if (employeePageHeadingHeight != null) {
    //         if (employeePageHeadingHeight.classList.contains(".mini-navbar")) {
    //             employeePageHeadingHeight.classList.add("scrolling-navbar");
    //             employeePageHeadingHeight.classList.add("top-nav-collapse");
    //         }
    //         else {
    //             employeePageHeadingHeight.classList.remove("scrolling-navbar");
    //             employeePageHeadingHeight.classList.remove("top-nav-collapse");
    //         }
    //     }
    // }
    if (topNavBarHeight != null && topNavMenuHeight != null && topNavBUMenuHeight != null && employeePageHeadingHeight != null) {
        let detailsLeftSectionTopSpace = topNavBarHeight.offsetHeight + topNavMenuHeight.offsetHeight + topNavBUMenuHeight.offsetHeight + employeePageHeadingHeight.offsetHeight;

        if (wScroll > detailsLeftSectionTopSpace) {
            if (detailsLeftSection != null) {
                detailsLeftSection.classList.add("scrolled220");
            }
        }
        else if (wScroll < detailsLeftSectionTopSpace) {
            if (detailsLeftSection != null) {
                detailsLeftSection.classList.remove("scrolled220");
            }
        }
    }
    if (wScroll > 88) {
        if (topNavBUMenu != null) {
            topNavBUMenu.classList.add("scrolled100");
            topNavBUMenuHeight.style.top = topNavMenuHeight.offsetHeight + "px";
        }
    }
    else if (wScroll < 88) {
        if (topNavBUMenu != null) {
            topNavBUMenu.classList.remove("scrolled100");
        }
    }
}

export function toggleLeftSideMenuLinkClicked() {
    var sidenav = document.querySelector(".sidebar-nav");
    if (sidenav != null) { sidenav.classList.remove("mini-navbar"); };

    var transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop != null) { transparentBackdrop.classList.remove("show"); };

    var listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader != null) { listPageHeader.classList.remove("mini-navbar"); };

    var listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent != null) { listPageContent.classList.remove("mini-navbar"); };

    var topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile != null) { topNavMenuProfile.classList.remove("mini-navbar"); }
}

export function toggleRightSideMenuLinkClicked() {
    var rightnav = document.querySelector(".rightSidebar");
    if (rightnav != null) { rightnav.classList.remove("show"); };

    var rightnavpoptriangle = document.querySelector(".pop-tiangle");
    if (rightnavpoptriangle != null) { rightnavpoptriangle.classList.remove("show"); };

    var outsideClick = document.querySelector(".outsideClick");
    if (outsideClick != null) { outsideClick.classList.remove("show"); };
}

export const getCurrentBU = () => {
    let url = "Portal/Current";
    return new Promise<BusinessUnitRow>((resolve, reject) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                let businessUnitRow: BusinessUnitRow = response.data.Entity as BusinessUnitRow;
                resolve(businessUnitRow);
            }
            else {
                resolve(null);
            }
        }).catch((error) => error);
    });
};

export const getUserClientInfo = () => {
    let url = "User/GetClientInfo";
    return new Promise<ClientInfoRow>((resolve/*, reject*/) => {
        return repositoryActions.getData(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)) {
                resolve(response.data);
            }
            else {
                resolve(null);
            }
        })
            .catch((error) => resolve(null));
    });
};

export const getUserInfo = () => {
    //let url = "Employee/UserInfo";
    let url = "User/UserInfo";
    return new Promise<UserInfoRow | number>((resolve, reject) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            {},
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data)) {
                resolve(response.data);
            }
            else {
                resolve(null);
            }
        }).catch((error) => error);
    });
};

export const IsUserImpersonated = () => {
    return new Promise<boolean>((resolve, reject) => {
        repositoryActions.postDataAndGetResponse('User/IsLogInImpersonatedUser', null, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    if (response.data === true)
                        resolve(true);
                    else
                        resolve(false);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => error);
    });
};

export const LogoutImpersonatedUser = () => {
    //code start for clear bu on logout
    //sessionStorage.removeItem(StorageKey.CurrentPortal);
    const msalInstanceCurrentUser = msalInstance.currentUser;
    msalInstanceCurrentUser.CurrentPortal = null;
    msalInstanceCurrentUser.CurrentPortalUID = null;
    msalInstanceCurrentUser['currentBusinessUnitId'] = null;
    msalInstance.currentUser = msalInstanceCurrentUser;


    let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
    if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
        currentUser.CurrentPortal = null;
        currentUser.CurrentPortalUID = null;
        currentUser['currentBusinessUnitId'] = null;
    }

    setSessionStorage(StorageKey.CurrentPortal, true, null);
    setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));

    //code end for clear bu on logout

    repositoryActions.postDataAndGetResponse("User/LogoutImpersonatedUser", null, null, ContentType.applicationJson)
        .then((response) => {
            if (isNotNullAndUndefined(response.data)) {
                //showSuccessToast('Impersonated User logged out', 'Success');
                sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                sessionStorage.removeItem(StorageKey.MenuModel);
                sessionStorage.removeItem(StorageKey.Terminologies);
                sessionStorage.removeItem(StorageKey.all_businessUnit);
                setImpersonateUserDefinition(null);
                window.location.href = '/HR/Employee/List';
            }
        }).catch((error) => error);
};

export const switchTenant = (
    selectedTenantId: string,
    selectedUserID: string,
    forceLogin: boolean = false//in switch portal, we need to force login but in switch tenant, we don't need to force login and just check if same tenant is selected
) => {
    return new Promise<boolean>((resolve) => {
        if (
            isNotNullAndUndefined(selectedTenantId) &&
            isNotNullAndUndefined(selectedUserID)
        ) {
            if (
                forceLogin ||
                (msalInstance.currentTenantId !== selectedTenantId ||
                    msalInstance.currentUserId !== selectedUserID)
            ) {
                const logInAsRequestRow: LogInAsRequestRow = new LogInAsRequestRow();
                logInAsRequestRow.EmployeeUID = selectedUserID;
                logInAsRequestRow.TenantUID = selectedTenantId;

                postDataAndGetResponse(
                    "User/LogInAs",
                    logInAsRequestRow,
                    null,
                    ContentType.applicationJson,
                    false
                ).then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                        sessionStorage.removeItem(StorageKey.MenuModel);
                        sessionStorage.removeItem(StorageKey.Terminologies);
                        sessionStorage.removeItem(StorageKey.all_businessUnit);
                        setImpersonateUserDefinition(response.data);

                        const responseDataTenants = response.data.Tenants;
                        const selectedTenant: TenantRow = isNotEmptyArray(responseDataTenants) ? responseDataTenants.find((x) => x.UID === selectedTenantId) : null;
                        if (isNotNullAndUndefined(selectedTenant)) {
                            window.localStorage.setItem(
                                StorageKey.currentTenant,
                                JSON.stringify({
                                    UID: selectedTenant.UID,
                                    DisplayName: selectedTenant.DisplayName,
                                    TenantName: selectedTenant.TenantName,
                                    SubDomain: selectedTenant.SubDomain,
                                })
                            );
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
};

export const switchPortal = (
    selectedTenantId: string,
    selectedUserID: string,
    selectedPortalID: string,
    forceLogin: boolean = false//in switch portal, we need to force login but in switch tenant, we don't need to force login and just check if same tenant is selected
) => {
    return new Promise<boolean>((resolve) => {
        if (
            isNotNullAndUndefined(selectedTenantId) &&
            isNotNullAndUndefined(selectedUserID)
        ) {
            if (
                forceLogin ||
                (msalInstance.currentTenantId !== selectedTenantId ||
                    msalInstance.currentUserId !== selectedUserID)
            ) {
                const logInAsRequestRow: LogInAsRequestRow = new LogInAsRequestRow();
                logInAsRequestRow.EmployeeUID = selectedUserID;
                logInAsRequestRow.TenantUID = selectedTenantId;
                logInAsRequestRow.PortalUID = selectedPortalID;

                postDataAndGetResponse(
                    "User/SwitchPortal",
                    logInAsRequestRow,
                    null,
                    ContentType.applicationJson,
                    false
                ).then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                        sessionStorage.removeItem(StorageKey.MenuModel);
                        sessionStorage.removeItem(StorageKey.Terminologies);
                        sessionStorage.removeItem(StorageKey.all_businessUnit);
                        setImpersonateUserDefinition(response.data);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
};

export const getBusinessUnits = () => {
    let dataManagerRequest = new DataManager();
    return new Promise<{ BusinessUnitRow; }>((resolve, reject) => {
        const allViewsStorage = sessionStorage.getItem(StorageKey.all_businessUnit);
        if (
            isNotNullAndUndefined(allViewsStorage) &&
            !IsNullOrWhiteSpace(allViewsStorage)
        ) {
            const allBusinessUnits = JSON.parse(allViewsStorage);
            resolve(allBusinessUnits);
        } else {
            return repositoryActions
                .postDataAndGetResponse(
                    `${Constants.baseAPIUrl}Portal/GetPortalHierarchy`,
                    dataManagerRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        const allBusinessUnits = response.data;
                        sessionStorage.setItem(
                            StorageKey.all_businessUnit,
                            JSON.stringify(allBusinessUnits)
                        );
                        resolve(allBusinessUnits);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => error);
        }
    });
};

export const navigateToDashboard = (navigate?: NavigateFunction) => {
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.Default);

    setTimeout(() => {
        if (navigate) {
            navigate('/HR/Employee/List');
        } else {
            window.location.href = '/HR/Employee/List';
        }
    });
};

export const AdministrationClicked = (navigate?: NavigateFunction) => {
    setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.AdminSettings);
    setTimeout(() => {
        if (isNotNullAndUndefined(navigate)) {
            navigate('/PermissionGroup/list');
        } else {
            window.location.href = '/PermissionGroup/list';
        }
    });
};
