
import { RAFEntityBase } from '../../../../RAFComponents/models/Common/RAFEntityBase';
import { Guid, propertyOf } from '../../../../RAFComponents/helpers/utils';
export class ILogInAsRequestDto {
    EmployeeUID?: string;
    TenantUID?: string;
    PortalUID?: string;
}

export class ILogInAsRequestRow {
    EmployeeUID?: string;
    TenantUID?: string;
    PortalUID?: string;
}

export class LogInAsRequestRow extends ILogInAsRequestRow implements RAFEntityBase {
    Employee?: string;
    Tenant?: string;

    getClassName?() {
        return 'Employee';
    }
    getIdField?(): string {
        return propertyOf<LogInAsRequestRow>("TenantUID");
    }
}

export class LogInAsRequest {
    UserUID?: string;
    TenantUID?: string;
    PortalUID?: string;
}

export class UserDefinition {
    Id?: string;
    DisplayName?: string;
    Email?: string;
    IsActive?: boolean;
    UserUID?: string;
    Username?: string;
    UserGroupUID?: string;
    BusinessUnitUID?: string;
    BusinessUnitID?: string;
    BusinessUnit?: string;
    TenantUID?: string;
    TenantID?: string;
    Impersonated?: string;
}