
export class SkyConstants {
    static skytrustUrl = 'http://skytrust.co';
    static skytrustWebAPIUrl = 'http://skytrust.co/';
}

export enum SkyLoginStatus {
    NotSet = 'Not Set',
    Active = 'Active',
    InActive = 'In Active',
    PendingInvite = 'Pending Invite',
    PendingLogin = 'Pending Login',
    InviteSent = 'Invite Sent',
}

export class SkyStorageKey {
    static recentClickedUrl_moduleName = 'recentClickedUrl_';
}

export class ModuleName {
    static Employee = "EMPLOYEE";
    static Location = "LOCATION";
    static BusinessUnit = "BUSINESSUNIT";
    static CommunityImpact = "COMMUNITYIMPACT";
    static EnvironmentalImpact = "ENVIRONMENTALIMPACT";
    static Hazard = "HAZARD";
    static IncidentSecurity = "INCIDENTSECURITY";
    static Incident = "INCIDENT";
    static NotifiableIncident = "NOTIFIABLEINCIDENT";
    static OtherIncident = "OTHERINCIDENT";
    static PersonalInjury = "PERSONALINJURY";
    static PropertyDamage = "PROPERTYDAMAGE";
    static Equipment = "EQUIPMENT";;
}

export enum MigrationStatus {
    Pending = "Pending",
    Ready = "Ready",
    Migrated = "Migrated"
}

export enum MigrationStatusValue {
    Pending = 0,
    Ready = 1,
    Migrated = 2
}

export enum EmpLoginStatusText {
    Unverified = 'Unverified',
    SecureIDPending = 'SecureID Pending',
    SecureID2FAPending = 'SecureID 2FA Pending',
    SecureIDVerified = 'SecureID Verified',
    SecureID2FAVerified = 'SecureID 2FA Verified',
    MSSSOPending = 'MS SSO Pending',
    MSSSOActivated = 'MS SSO Activated',
    NewuserPending = 'Newuser Pending',
    ActivateSecureID = 'Activate SecureID',
    ActivateMicrosoftSSO = 'Activate Microsoft SSO',
    ActivateNewuser = 'Activate New User',
}

export enum EmpLoginStatusValue {
    Unverified = 0,
    SecureIDPending = 1,
    SecureIDVerified = 2,
    MSSSOPending = 3,
    MSSSOActivated = 4,
    NewuserPending = 5,
    ActivateSecureID = 1,
    ActivateMicrosoftSSO = 3,
    ActivateNewuser = 5,
}

export enum btnContentString {
    save = "Save",
    cancel = "Cancel",
    edit = "Edit",
    add = "Add",
}

export enum btnIdString {
    save = "save",
    cancel = "cancel",
    edit = "edit",
    add = "add",
}

export enum btnIconName {
    save = "far fa-floppy-disk",
    cancel = "far fa-xmark",
    edit = "fas fa-pen-to-square",
    add = "fas fa-plus",
    migrate = "fa fa-merge",
}

export enum UserLoginFlow {
    SecureID2FAVerified = 21,
}