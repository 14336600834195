import { DataManager } from "@syncfusion/ej2-data";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Component } from "react";
import { FormRenderProps } from "react-final-form";
import SkyDropdownList from "../../../components/shared/SkyFormFields/SkyDropdownList";
import SkyLoadingPanel from "../../../components/shared/SkyLoadingPanel";
import { IsUserImpersonated } from "../../../helpers/SkyUtils";
import { IsSuperAdmin, getImpersonateUserDefinition, getSessionStorage, hideProgress, setImpersonateUserDefinition, setSessionStorage, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { ContentType, StorageKey } from '../../../RAFComponents/helpers/Constants';
import { IDialogProps, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { TenantRow } from "../../../RAFComponents/models/Common/TenantRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { withRouter } from "../../../router";
import { ConvertLogInAsRequestRowToLogInAsRequestDto } from "./Helpers/LogInAsRequestMappingRegister";
import { ILogInAsRequestDto, LogInAsRequestRow, UserDefinition } from "./Models/LogInAsRequestRow";
import RAFDropdown from "../../../RAFComponents/Inputs/RAFDropdown";
import SkyCustomTextBox from "../../../components/shared/SkyFormFields/SkyCustomTextBox";
import { getFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import { CanImpersonateUser, configureTenantByTenantUID, getAllUsersByTenantUID } from "../../User/UserHelper";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { msalInstance } from "../../..";
import { UserInfoRow } from "../../../RAFComponents/models/Common/UserInfoRow";

interface IProps {
    isActive: boolean,
    hideDialogHeader: boolean,
}

interface IState {
    isActive: boolean,
    canImpersonate: boolean,
    isImpersonatedUser: boolean,
    isLoading: boolean,
    allTenants: TenantRow[],
    allEmployees: LookUpRow[],
    impersonatedUser: UserDefinition;
}

class ImpersonatePage extends Component<IDialogProps & IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            canImpersonate: false,
            isImpersonatedUser: false,
            allTenants: [],
            allEmployees: [],
            impersonatedUser: {},
            isLoading: true
        };
    }

    public rafForm: FormRenderProps | null;
    public submitButton1: ButtonComponent;
    _isMounted = false;

    componentDidMount = () => {
        this._isMounted = true;
        this.refresh(this.props.isActive);
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    enableSubmitButton = (g: FormRenderProps | null) => {
        if (g) {
            if (isNotNullAndUndefined(this.submitButton1)) {
                this.submitButton1.disabled = !g.valid || g.submitting;
            }
        }
    };

    getTenants = () => {
        return new Promise<TenantRow[]>((resolve, reject) => {
            let dataManagerRequest = new DataManager();
            repositoryActions.postDataAndGetResponse('Tenant/ListAll', dataManagerRequest, { ...this.props }, ContentType.applicationJson)
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    }
                }).catch((error) => error);
        });
    };

    onTenantsSelected = async (tenantUID) => {
        let progressDiv = showProgress('#impersonateDiv');
        const allEmployees = await getAllUsersByTenantUID(tenantUID);
        hideProgress(progressDiv);
        if (this._isMounted) {
            this.setState({ allEmployees: allEmployees });
        }
    };

    LogoutImpersonatedUser = () => {
        let progressDiv = showProgress('#impersonateDiv');
        //code start for clear bu on logout
        //sessionStorage.removeItem(StorageKey.CurrentPortal);
        const msalInstanceCurrentUser = msalInstance.currentUser;
        msalInstanceCurrentUser.CurrentPortal = null;
        msalInstanceCurrentUser.CurrentPortalUID = null;
        msalInstanceCurrentUser['currentBusinessUnitId'] = null;
        msalInstance.currentUser = msalInstanceCurrentUser;


        let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
        if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
            currentUser.CurrentPortal = null;
            currentUser.CurrentPortalUID = null;
            currentUser['currentBusinessUnitId'] = null;
        }

        setSessionStorage(StorageKey.CurrentPortal, true, null);
        setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));

        //code end for clear bu on logout
        repositoryActions.postDataAndGetResponse('User/LogoutImpersonatedUser', null, { ...this.props }, ContentType.applicationJson)
            .then((response) => {
                setTimeout(() => {
                    hideProgress(progressDiv);
                }, 500);
                if (isNotNullAndUndefined(response.data)) {
                    //showSuccessToast('Impersonated User logged out', 'Success');
                    sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                    sessionStorage.removeItem(StorageKey.MenuModel);
                    sessionStorage.removeItem(StorageKey.Terminologies);
                    sessionStorage.removeItem(StorageKey.all_businessUnit);
                    setImpersonateUserDefinition(null);
                    window.location.href = '/HR/Employee/List';
                }
            }).catch((error) => error);
    };

    onSubmit = (value) => {
        let loginRequest: LogInAsRequestRow = value as LogInAsRequestRow;
        let loginRequestDto: ILogInAsRequestDto = ConvertLogInAsRequestRowToLogInAsRequestDto(loginRequest);
        let progressDiv = showProgress('#impersonateDiv');
        repositoryActions.postDataAndGetResponse('User/LogInAs', loginRequestDto, null, ContentType.applicationJson)
            .then((response) => {
                setTimeout(() => {
                    hideProgress(progressDiv);
                }, 500);
                if (isNotNullAndUndefined(response.data)) {
                    sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                    sessionStorage.removeItem(StorageKey.MenuModel);
                    sessionStorage.removeItem(StorageKey.Terminologies);
                    sessionStorage.removeItem(StorageKey.all_businessUnit);
                    setImpersonateUserDefinition(response.data);

                    const responseDataTenants = response.data.Tenants;
                    const selectedTenant: TenantRow = isNotEmptyArray(responseDataTenants) ? responseDataTenants.find((x) => x.UID === loginRequest.TenantUID) : null;
                    if (isNotNullAndUndefined(selectedTenant)) {
                        window.localStorage.setItem(
                            StorageKey.currentTenant,
                            JSON.stringify({
                                UID: selectedTenant.UID,
                                DisplayName: selectedTenant.DisplayName,
                                TenantName: selectedTenant.TenantName,
                                SubDomain: selectedTenant.SubDomain,
                            })
                        );
                    }

                    window.location.href = '/HR/Employee/List';
                    //window.location.href = '/user/List';
                }
            }).catch((error) => error);
    };

    refresh = (isActive) => {
        if (isActive) {
            IsUserImpersonated().then((isImpersonatedUser) => {
                if (isNotNullAndUndefined(isImpersonatedUser) && isImpersonatedUser === true) {
                    let impersonatedUser1 = getImpersonateUserDefinition();
                    this.setState({ isImpersonatedUser, impersonatedUser: impersonatedUser1, isActive, isLoading: false });
                }
                else {
                    CanImpersonateUser().then((canImpersonate) => {
                        if (canImpersonate === true) {
                            this.getTenants().then((allTenants) => {
                                this.setState({ canImpersonate, allTenants, isActive, isLoading: false });
                            });
                        }
                        else {
                            this.setState({ canImpersonate, isActive, isLoading: false });
                        }
                    });
                }
            });
        } else {
            if (this._isMounted) {
                this.setState({ isLoading: false });
            }
        }
    };

    onClickConfigureTenant = async () => {
        const tenantUID = getFormValue(this.rafForm, propertyOf<LogInAsRequestRow>("TenantUID"));
        let progressDiv = showProgress('#impersonateDiv');
        const configureTenant = await configureTenantByTenantUID(tenantUID);
        if (configureTenant) {
            hideProgress(progressDiv);
            const allEmployees = await getAllUsersByTenantUID(tenantUID);
            if (this._isMounted) {
                this.setState({ allEmployees: allEmployees });
            }
        } else {
            hideProgress(progressDiv);
        }
    };

    render() {
        let { canImpersonate, isImpersonatedUser, allTenants, allEmployees, impersonatedUser, isLoading } = this.state;
        let loginAsRequestRow: LogInAsRequestRow = new LogInAsRequestRow();

        return (
            <RAFForm formRef={g => { this.enableSubmitButton(g); return this.rafForm = g; }} type={LogInAsRequestRow} initialValues={loginAsRequestRow} onSubmit={this.onSubmit}>
                <div className="impersonate-outer-container e-dlg-content-outer" id="impersonateDiv">
                    {this.props.hideDialogHeader !== true && (
                        <div className="e-dlg-headerContent" style={{ background: '#0177c1', color: '#fff' }}>
                            <div className="d-flex align-items-center">
                                <span className="fa fa-user-friends"></span>
                                <h5 className="ms-2 mt-2 ecllipseSecondLine">
                                    Switch User
                                </h5>
                            </div>
                            <RAFButtonComponent
                                displayMode="DialogCloseBtn"
                                className="raf_sm btn-neutral"
                                onClick={this.props.onClose}
                            />
                            {/* <button className="e-dlg-closeicon-btn e-control e-btn e-lib e-flat e-icon-btn bg-transparent border-0" type="button" onClick={() => this.props.onClose()}>
                                <span className="e-btn-icon e-icon-dlg-close e-icons"></span>
                            </button> */}
                        </div>
                    )}
                    <div className="e-dlg-body-content">
                        {isLoading === false ?
                            <div>
                                {isImpersonatedUser === true ?
                                    <div className="w-100 my-4 py-1">
                                        <div className="w-100 d-flex justify-content-center text-center">
                                            <h3>You are now logged in as {impersonatedUser && impersonatedUser.DisplayName}</h3>
                                        </div>
                                        {/* <div className="w-100 d-flex justify-content-center my-4">
                                            <div style={{ width: '90px' }}>
                                                <ButtonComponent type="button" cssClass='e-flat e-primary primary-btn me-2 w-100' onClick={() => this.LogoutImpersonatedUser()}>Logout</ButtonComponent>
                                            </div>
                                        </div> */}
                                    </div>
                                    :
                                    canImpersonate === true ?
                                        <div className="w-100">
                                            <SkyCustomTextBox
                                                label={"Tenant"}
                                                required
                                                width="100%"
                                                customchildrenWithCol={true}
                                                inputFieldrowClassName="g-3"
                                            >
                                                <div className="col">
                                                    <RAFDropdown<LogInAsRequestRow> field="TenantUID" label="Tenant" showLabel={false} onChanged={(label, value) => this.onTenantsSelected(value)} allowFiltering={true} required>
                                                        {allTenants && allTenants.map(item => {
                                                            return <RAFChoiceOption key={item.UID.toString()} label={item.TenantName} value={item.UID} />;
                                                        })}
                                                    </RAFDropdown>
                                                </div>
                                                {IsSuperAdmin() && (
                                                    <div className="col-auto d-flex align-items-center">
                                                        <ButtonComponent
                                                            type="button"
                                                            title={"Sync Tenant"}
                                                            cssClass="e-outline"
                                                            onClick={() => this.onClickConfigureTenant()}
                                                            content={"Sync Tenant"}
                                                            id={`btn_SYNC`}
                                                        >
                                                            SYNC
                                                        </ButtonComponent>
                                                    </div>
                                                )}
                                            </SkyCustomTextBox>
                                            <SkyDropdownList<LogInAsRequestRow> field="EmployeeUID" allowFiltering={true} required label="User">
                                                {allEmployees && allEmployees.map(item => {
                                                    return <RAFChoiceOption key={item.UID.toString()} label={item.Value} value={item.UID} />;
                                                })}
                                            </SkyDropdownList>
                                            {/* <div className="justify-content-center e-dlg-footerContent">
                                                <div style={{ width: '90px' }}>
                                                    <ButtonComponent ref={g => this.submitButton1 = g} type="submit" cssClass='e-flat e-primary primary-btn me-2 w-100'>Login</ButtonComponent>
                                                </div>
                                            </div> */}
                                        </div>
                                        :
                                        <div>
                                            <div className="d-flex justify-content-center my-2"><h3>You are not allowed to impersonate</h3></div>
                                        </div>
                                }

                            </div>
                            :
                            <div className="container-fluid px-0" >
                                <SkyLoadingPanel />
                            </div>
                        }
                    </div>
                    <div className={this.props.hideDialogHeader === true ? "d-flex justify-content-center p-3" : "e-dlg-footerContent align-items-center"} >
                        {isImpersonatedUser === true ?
                            <ButtonComponent type="button"
                                isPrimary
                                onClick={() => this.LogoutImpersonatedUser()}>Logout</ButtonComponent>
                            :
                            canImpersonate === true ?
                                <ButtonComponent ref={g => this.submitButton1 = g} type="submit"
                                    isPrimary
                                >Login</ButtonComponent>
                                :
                                ""
                        }
                    </div>
                </div>
            </RAFForm>
        );
    }
}
export default withRouter(ImpersonatePage);
