import {
    DialogComponent
} from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React, { PropsWithChildren, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { msalInstance } from "../..";
import RAFIconImage from "../../RAFComponents/Navigation/RAFIconImage";
import { RAFFullScreenContext } from "../../RAFComponents/Providers/RAFFullScreenContextProvider";
import { Logout, getSessionStorage, setImpersonateUserDefinition, setSessionStorage } from "../../RAFComponents/helpers/AppHelper";
import { ContentType, StorageKey } from "../../RAFComponents/helpers/Constants";
import { RAFEventName, triggerRAFEvent } from "../../RAFComponents/helpers/RAFDocumentEvents";
import { MenuCategory } from "../../RAFComponents/helpers/RAFMenuHelper";
import {
    isNotNullAndUndefined
} from "../../RAFComponents/helpers/utils";
import { TenantRow } from "../../RAFComponents/models/Common/TenantRow";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { CurrentWorkspaceContext } from "../../RAFModules/Common/Providers/WorkspaceContextProvider";
import RAFBusinessUnitDialogContent from "../../SkytrustModules/BusinessUnit/RAFBusinessUnitDialogContent";
import ImpersonatePage from "../../SkytrustModules/HR/Employee/ImpersonatePage";
import { AdministrationClicked, IsUserImpersonated } from "../SkyUtils";
import "./RAFProfileCard.scss";
import { UserInfoRow } from "../../RAFComponents/models/Common/UserInfoRow";

interface IProps {
    arrowTipClass?: string;
    uiMode?: 'fullBtn';
    onUpdatMenuCategory?: (menu: string) => void;
    hasPermissionToViewAdministration: boolean;
}

function RAFProfileCard({
    ...props
}: PropsWithChildren<IProps>) {
    let navigate = useNavigate();

    let rafDropdownButtonComponent = useRef<DropDownButtonComponent>(null);

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            showSwitchUserPanelContent: false as boolean,
            showWorkspaceContent: false as boolean,
        }
    );

    const currentWorkspaceContext = React.useContext(CurrentWorkspaceContext);
    const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext) ? currentWorkspaceContext.currentWorkspace : null;
    const workspaceName = isNotNullAndUndefined(currentWorkspace) ? currentWorkspace.PortalName : null;

    const navigateToPortalList = () => {
        navigate('/BusinessUnit/List');
    };

    function switchUserDialogClicked() {
        setState({ showSwitchUserPanelContent: true });
    };

    function switchUserDialogClose() {
        setState({ showSwitchUserPanelContent: false });
    };
    const switchUserDialogContent = () => {
        if (
            isNotNullAndUndefined(state.showSwitchUserPanelContent) &&
            state.showSwitchUserPanelContent === true
        ) {
            return (
                <ImpersonatePage onClose={switchUserDialogClose} isActive />
            );
        } else {
            return <div></div>;
        }
    };

    const LogoutImpersonatedUser = () => {
        //code start for clear bu on logout
        //sessionStorage.removeItem(StorageKey.CurrentPortal);
        const msalInstanceCurrentUser = msalInstance.currentUser;
        msalInstanceCurrentUser.CurrentPortal = null;
        msalInstanceCurrentUser.CurrentPortalUID = null;
        msalInstanceCurrentUser['currentBusinessUnitId'] = null;
        msalInstance.currentUser = msalInstanceCurrentUser;


        let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
        if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
            currentUser.CurrentPortal = null;
            currentUser.CurrentPortalUID = null;
            currentUser['currentBusinessUnitId'] = null;
        }

        setSessionStorage(StorageKey.CurrentPortal, true, null);
        setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));

        //code end for clear bu on logout
        repositoryActions.postDataAndGetResponse('User/LogoutImpersonatedUser', null, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    //showSuccessToast('Impersonated User logged out', 'Success');
                    sessionStorage.removeItem(StorageKey.currentBusinessUnit);
                    sessionStorage.removeItem(StorageKey.MenuModel);
                    sessionStorage.removeItem(StorageKey.Terminologies);
                    sessionStorage.removeItem(StorageKey.all_businessUnit);
                    setImpersonateUserDefinition(null);
                    window.location.href = '/HR/Employee/List';
                }
            }).catch((error) => error);
    };

    const RefreshUserStorage = (reloadSamePage = false) => {
        sessionStorage.clear();
        localStorage.removeItem(StorageKey.currentBusinessUnit);
        localStorage.removeItem("survey-json");

        setTimeout(() => {
            if (reloadSamePage === true) {
                window.location.reload();
            }
            else {
                window.location.href = '/';
            }
        });
    };

    const closeDropdownBtn = () => {
        let customDropdownMenu = document.querySelectorAll(".raf-ProfileCardDDB");
        if (isNotNullAndUndefined(customDropdownMenu)) {
            customDropdownMenu.forEach((item) => {
                item.classList.remove("e-popup-open");
                item.classList.add("e-popup-close");
            });
        }
    };

    function profileDropdownBtnClicked(e) {
        e.stopPropagation();
        if (isNotNullAndUndefined(rafDropdownButtonComponent) && isNotNullAndUndefined(rafDropdownButtonComponent.current) && isNotNullAndUndefined(rafDropdownButtonComponent.current.close)) {
            rafDropdownButtonComponent.current.close();
        }
        let profileDivSwitchUserBtn = document.getElementsByClassName("profileDivSwitchUserBtn");
        let profileDivUserSettingsBtn = document.getElementsByClassName("profileDivUserSettingsBtn");
        let profileDivSwitchBtn = document.getElementsByClassName("profileDivSwitchBtn");
        let profileDivSwitchBUBtn = document.getElementsByClassName("profileDivSwitchBUBtn");
        let profileDivMyPortalBtn = document.getElementsByClassName("profileDivMyPortalBtn");
        let profileDivAdministrationBtn = document.getElementsByClassName("profileDivAdministrationBtn");
        let profileDivUserLogOutBtn = document.getElementsByClassName("profileDivUserLogOutBtn");
        let profileDivUserRefreshDataBtn = document.getElementsByClassName("profileDivUserRefreshDataBtn");

        if (isNotNullAndUndefined(profileDivSwitchUserBtn)) {
            for (let i = 0; i < profileDivSwitchUserBtn.length; i++) {
                let profileDivSwitchUserHTMLBtn = profileDivSwitchUserBtn[i] as HTMLElement;
                profileDivSwitchUserHTMLBtn.onclick = () => {
                    closeDropdownBtn();
                    switchUserDialogClicked();
                };
            }
        }
        if (isNotNullAndUndefined(profileDivSwitchBUBtn)) {
            for (let i = 0; i < profileDivSwitchBUBtn.length; i++) {
                let profileDivSwitchBUHTMLBtn = profileDivSwitchBUBtn[i] as HTMLElement;
                profileDivSwitchBUHTMLBtn.onclick = () => {
                    closeDropdownBtn();
                    triggerRAFEvent(RAFEventName.OpenBusinessUnitDialog, null);
                };
            }
        }
        if (isNotNullAndUndefined(profileDivMyPortalBtn)) {
            for (let i = 0; i < profileDivMyPortalBtn.length; i++) {
                let profileDivSwitchBUHTMLBtn = profileDivMyPortalBtn[i] as HTMLElement;
                profileDivSwitchBUHTMLBtn.onclick = () => {
                    closeDropdownBtn();
                    navigateToPortalList();
                };
            }
        }
        if (isNotNullAndUndefined(profileDivAdministrationBtn)) {
            for (let i = 0; i < profileDivAdministrationBtn.length; i++) {
                let profileDivAdministrationBtnHTMLBtn = profileDivAdministrationBtn[i] as HTMLElement;
                profileDivAdministrationBtnHTMLBtn.onclick = () => {
                    closeDropdownBtn();
                    AdministrationClicked(navigate);
                    if (props.onUpdatMenuCategory) {
                        props.onUpdatMenuCategory(MenuCategory.AdminSettings);
                    }
                };
            }
        }
        if (isNotNullAndUndefined(profileDivUserLogOutBtn)) {
            for (let i = 0; i < profileDivUserLogOutBtn.length; i++) {
                let profileDivUserLogOutHTMLBtn = profileDivUserLogOutBtn[i] as HTMLElement;
                profileDivUserLogOutHTMLBtn.onclick = () => {
                    IsUserImpersonated().then((isImpersonatedUser) => {
                        if (isNotNullAndUndefined(isImpersonatedUser) && isImpersonatedUser === true) {
                            closeDropdownBtn();
                            LogoutImpersonatedUser();
                        }
                        else {
                            closeDropdownBtn();
                            Logout();
                        }
                    });
                };
            }
        }
        if (isNotNullAndUndefined(profileDivUserRefreshDataBtn)) {
            for (let i = 0; i < profileDivUserRefreshDataBtn.length; i++) {
                let profileDivUserRefreshDataHTMLBtn = profileDivUserRefreshDataBtn[i] as HTMLElement;
                profileDivUserRefreshDataHTMLBtn.onclick = () => {
                    closeDropdownBtn();
                    RefreshUserStorage();
                };
            }
        }
    };

    //Tooltip react template

    function tooltipTemplate(): JSX.Element {
        let currentUserTenants = msalInstance.currentTenants;
        const userName = msalInstance.currentUserName;
        const userEmail = msalInstance.currentUserEmail;
        let userNameInitial = isNotNullAndUndefined(userName) && userName.charAt(0).toUpperCase();
        let selectedTenant: TenantRow =
            currentUserTenants &&
            currentUserTenants.find((x) => x.UID === msalInstance.currentTenantId);
        let htmlTooltipContent = <div><div className="e-card profile-card customScrollBar d-block" id="pageHeaderProfileCard">
            <div className="e-card-header p-3">
                <div className="w-100">
                    <div className="row flex-nowrap g-2 align-items-center">
                        <div className="col-auto">
                            <div className={`e-avatar e-avatar-xlarge e-avatar-circle avatar-initial-palettes-${userNameInitial}`}>
                                <span>{userNameInitial}</span>
                            </div>
                        </div>
                        <div className="col overflow-auto">
                            <div className="e-card-header-title mt-1">{userName}</div>
                            <div className="text-truncate">{userEmail}</div>

                        </div>
                    </div>
                </div>

            </div>
            {/* <div className="e-card-separator"></div> */}
            {/* <div className="e-card-content d-flex justify-content-center px-4 ms-3">
                <div className="settingsItem w-100">
                    <div className="d-flex align-items-center">
                        <span className="fa fa-sitemap me-2"></span>
                        <span className="ms-2">{selectedTenant && selectedTenant.DisplayName}</span>
                    </div>
                    <div>
                        {isNotNullAndUndefined(currentUserTenants) && currentUserTenants.length > 1 ?
                            <button id="profileDivSwitchBtn" className="profileDivSwitchBtn e-control e-btn e-lib e-outline e-info m-0">Switch</button>
                            :
                            ''
                        }
                    </div>
                </div>
            </div> */}
            <div className="e-card-content p-0 border-top">
                {/* <button id="profileDivSwitchUserBtn" className="profileDivSwitchUserBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md" >
                    <span className="e-btn-icon fa fa-user-friends e-icon-left" aria-hidden="true"></span>
                    Switch user
                </button> */}
                <button id="profileDivMyPortalBtn" className="profileDivMyPortalBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
                >
                    <span className="e-btn-icon fa fa-briefcase e-icon-left" aria-hidden="true">
                    </span>
                    My Business Units
                </button>
                {props.hasPermissionToViewAdministration && (
                    <button id="profileDivAdministrationBtn" className="profileDivAdministrationBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
                    >
                        <span className="e-btn-icon fa fa-briefcase e-icon-left" aria-hidden="true">
                        </span>
                        Administration
                    </button>
                )}

                {/* <button id="profileDivSwitchUserBtn" className="profileDivSwitchUserBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
                >
                    <span className="e-btn-icon fa fa-briefcase e-icon-left" aria-hidden="true">
                    </span>
                    Switch Tenant
                </button> */}
                {/* <button id="profileDivSwitchBUBtn" className="profileDivSwitchBUBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md"
                >
                    <span className="e-btn-icon fa fa-briefcase e-icon-left" aria-hidden="true">
                    </span>
                    Switch Business Unit
                </button> */}
                <button id="profileDivChangePasswordBtn" className="profileDivChangePasswordBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md">
                    <span className="e-btn-icon fa fa-lock e-icon-left" aria-hidden="true">
                    </span>
                    Change Password
                </button>
                <button id="profileDivUserRefreshDataBtn" className="profileDivUserRefreshDataBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md" >
                    <span className="e-btn-icon fa fa-retweet e-icon-left" aria-hidden="true">
                    </span>
                    Refresh Data
                </button>
                <button id="profileDivUserLogOutBtn" className="profileDivUserLogOutBtn e-control e-btn e-lib dropdown-menu-button unset-custom-button-md" >
                    <span className="e-btn-icon fa fa-sign-out-alt e-icon-left" aria-hidden="true">
                    </span>
                    Logout
                </button>
            </div>
        </div>
            <div className={isNotNullAndUndefined(props.arrowTipClass) ? `e-arrow-tip e-tip-top ${props.arrowTipClass}` : "e-arrow-tip e-tip-top"}>
                <div className="e-arrow-tip-outer e-tip-top"></div>
                <div className="e-arrow-tip-inner e-tip-top">
                </div>
            </div>
        </div>;
        return (
            htmlTooltipContent
            // <div id="democontent" className="democontent">
            //     <h3 style={{ marginTop: '10px' }}>Eastern Bluebird</h3>
            //     <img id="bird" src='./src/tooltip/images/bird.png' alt="bird_image" />
            //     <p>The
            //         <a href="https://en.wikipedia.org/wiki/Eastern_bluebird" target="_blank"> Eastern Bluebird </a>
            //         is easily found in open fields and sparse woodland areas, including along woodland edges. These are
            //         <i>cavity-nesting birds</i>
            //         and a pair of eastern bluebirds will raise 2-3 broods annually, with 2-8 light blue or whitish eggs
            //         per brood.</p>
            //     <hr style={{ marginTop: '10px 0px' }} />
            //     <p>Eastern bluebirds can be very vocal in flocks. Their calls include a rapid, mid-tone chatter and
            //         several long dropping pitch
            //         calls.</p>
            //     <p>Source:<br />
            //         <a href="https://en.wikipedia.org/wiki/Eastern_bluebird" target="_blank">https://en.wikipedia.org/wiki/Eastern_bluebird</a>
            //     </p>
            // </div>
        );
    }

    return (
        <RAFFullScreenContext.Consumer>
            {({ mode }) => {
                let { showSwitchTenantDialog } = state;

                const recentClickedWorkspace = JSON.parse(
                    getSessionStorage(StorageKey.CurrentPortal, true)
                );
                const userName = msalInstance.currentUserName;
                const userEmail = msalInstance.currentUserEmail;
                return (
                    mode === "default" && (
                        <div className="w-100">
                            <div className="customViewDropdownMenu" id={"profileCardContentContainer"} >
                                {tooltipTemplate()}
                            </div>
                            {props.uiMode === 'fullBtn' ?
                                <DropDownButtonComponent
                                    ref={rafDropdownButtonComponent}
                                    id='ddbtnProfileCard'
                                    target={"#profileCardContentContainer"}
                                    cssClass={'raf-ProfileCardDDB raf_profile_card showArrowtip'}
                                    onClick={(e) => profileDropdownBtnClicked(e)}

                                >
                                    <RAFIconImage iconCssClass={"fa fa-user"}
                                        moduleavatar="border-avatar bg-transparent-avatar"
                                        iconSize="30" fontSize="12"></RAFIconImage>
                                    <div className="w-100 ps-2">
                                        <div className="header-text-sm text-white ecllipseFirstLine word-break-word">{userName}
                                        </div>
                                        <div className="secondary-text text-white ecllipseFirstLine word-break-word">{workspaceName}</div>
                                    </div>

                                </DropDownButtonComponent>
                                :
                                <DropDownButtonComponent
                                    id='ddbtnProfileCard'
                                    ref={rafDropdownButtonComponent}
                                    target={"#profileCardContentContainer"}
                                    cssClass={'e-caret-hide ddbtnUserProfileCard customScrollBar showArrowtip e-round'}
                                    iconCss={"fas fa-user"}
                                    onClick={(e) => profileDropdownBtnClicked(e)}

                                >

                                </DropDownButtonComponent>
                            }
                            {state.showSwitchUserPanelContent === true && (
                                <DialogComponent
                                    visible={state.showSwitchUserPanelContent}
                                    cssClass="centerDialog-md create-dialog impersonatePage dlg-new-style"
                                    // ref={(dialog) => (switchUserDialog = dialog)}
                                    width="600px"
                                    height="500px"
                                    isModal={true}
                                    // overlayClick={this.switchUserDialogClose.bind(this)}
                                    showCloseIcon={true}
                                    target="body"
                                    closeOnEscape={false}
                                    close={switchUserDialogClose}
                                    content={switchUserDialogContent}
                                ></DialogComponent>
                            )}
                            <RAFBusinessUnitDialogContent />
                        </div>
                    )
                );
            }}
        </RAFFullScreenContext.Consumer>
    );
}

export default React.memo(RAFProfileCard);
